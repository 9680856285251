function smoothScroll(trig, dest) {
  $(trig).click(function(e){
      e.preventDefault();
      $("html, body").animate({ scrollTop: $(dest).offset().top }, 1000);
  });
}

function mobileNav() {
  var navElements = $('.navbar-burger, .navbar-menu');

  $('.navbar-burger').on('click', function() {
    var status = $(this).hasClass('is-active');
    if (status) {
      navElements.removeClass('is-active');
    } else {
      navElements.addClass('is-active');
    }
  });

  $('.navbar-item').on('click', function() {
    var grandParent = $(this).parent().parent();
    if (grandParent.hasClass('is-active')) {
      navElements.removeClass('is-active');
    }
  });
}




function imageSelector() {
  var tourImages = $('.sm-tour-image > img');
  var focusedImage = $('#tour-focus > img');

  tourImages.on('click', function() {
    var clickedSrc = $(this).attr('src');
    var focusedSrc = focusedImage.attr('src');

    $(this).fadeOut(700, function() {
      $(this).attr('src', focusedSrc);
      $(this).fadeIn(300);
    });

    focusedImage.fadeOut(200, function() {
      focusedImage.attr('src', clickedSrc);
      focusedImage.fadeIn(500);
    });

  });
}



$(function() {
  mobileNav();
  imageSelector();
  smoothScroll('#contact-scroll', '#store-invite');
});
